import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Voice Actions: Selection interface for operators * **`}</li>
      <li parentName="ul">{`Chatbot Studio: improvements to the visual alignment`}</li>
      <li parentName="ul">{`Panels: Added "last updates" to dashboard details. *`}</li>
      <li parentName="ul">{`Bug Fix: Fixed duplicate fields in panel rules.`}</li>
      <li parentName="ul">{`Bug Fix: On aggregation panels the widget field on rules is working correctly.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      